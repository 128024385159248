.otpContainer {
  display:flex;
  flex: 1;
  justify-content: space-around;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  font-size: 28px;
  text-align: center;
  border-radius: 8px;
  padding: 6px 0px;
  border: 1px solid #ccc;
}